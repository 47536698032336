'use strict'

###*
 # @ngdoc service
 # @name lpa.lpaDispatching.factory:AccountSwitcher

 # @description

###
angular
  .module 'lpaDispatching'
  .factory 'AccountSwitcher', [
    '$log'
    '$cookies'
    '$mdDialog'
    'Users'
    '$mdMedia'
    'RestUtils'
    'Restangular'
    'mundoConfiguration'
    'OAuthToken'
    ($log, $cookies, $mdDialog, Users, $mdMedia,RestUtils, Restangular, mundoConfiguration, OAuthToken) ->
      AccountSwitcherBase = {}

      AccountSwitcherBase.rest = Restangular.service('services/users/impersonation/oauth_v2')

      AccountSwitcherBase.popup = () ->

        options = {}
        options.allowCancel = true
        options.title = "app.accountswitcher"

        # options.users = GET USERS

        usersPromise = RestUtils.getFullList Users,
          'filter[]': [
            'userContexts.userContextGroups.label,EQ Dispatchers'
            'enabled,true'
          ]
          'sort': 'username,ASC'
        usersPromise.then (users) ->
          options.users = users

          $mdDialog
          .show
            controllerAs: 'accountswitcher'
            templateUrl: 'lpa/lpa-dispatching/views/lpa-dispatching-account-switcher.tpl.html'
            parent: angular.element document.body
            clickOutsideToClose: options.allowCancel
            fullscreen: ($mdMedia 'sm') || ($mdMedia 'xs')
            resolve:
              options: () ->
                options
              users: () ->
                options.users
              allowCancel: () ->
                options.allowCancel
              title: () ->
                options.title
            controller: [
              '$scope'
              '$mdDialog'
              'options'
              'users'
              'allowCancel'
              'title'
              ($scope, $mdDialog, options, users, allowCancel, title) ->
                $scope.options = options
                $scope.users = users
                $scope.allowCancel = allowCancel
                $scope.title = title

                $scope.hide = () ->
                  $mdDialog.hide()

                $scope.cancel = () ->
                  $mdDialog.cancel()

                $scope.switchAccounts = (uid) ->
                  switchPromise = AccountSwitcherBase.rest.post
                    user: uid
                    clientPublicId: mundoConfiguration.oauth.clientId

                  switchPromise.then (result) ->
                    OAuthToken.setToken result
                    location.reload()


                  $mdDialog.hide()

            ]

      AccountSwitcherBase
    ]
